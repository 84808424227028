import React from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { PageContext } from 'src/types'
import theme from 'src/lib/theme'

import PageContainer from 'src/components/PageContainer/PageContainer'
import Layout from 'src/components/Layout/Layout'
import StreamsSignupOrLoginPage from '../../components/StreamsSignupOrLoginPage/StreamsSignupOrLoginPage'
import { Helmet } from 'react-helmet'

const LoginPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()
  return (
    <Layout
      pageContext={pageContext}
      background={theme.namedColors.streamsBackground}
      hideSiteHeader
      hideSiteFooter
    >
      <Helmet
        title={t('Login to Chatterbug')}
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <PageContainer>
        <StreamsSignupOrLoginPage isLogin={true} />
      </PageContainer>
    </Layout>
  )
}

export default LoginPage
